import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import duration from 'dayjs/plugin/duration.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
// import { months } from 'dayjs/locale/ru';
import locale_ru from 'dayjs/locale/ru'; // ES 2015
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  //   const config = useRuntimeConfig();
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(customParseFormat);
  dayjs.locale(locale_ru);
  return {
    provide: {
      dayjs() {
        return dayjs;
      },
    },
  };
});
