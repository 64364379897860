import axios from 'axios';
import { useUserStore } from '~/store/user';

const instances = {
  web: () => {
    const config = useRuntimeConfig();
    const userStore = useUserStore();

    const instance = axios.create({
      baseURL: config.public.API_URL,
      withCredentials: true,
    });

    const createAxiosResponseInterceptor = () => {
      const interceptor = instance.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          if (error.response.status !== 401) throw error;

          instance.interceptors.response.eject(interceptor);

          try {
            await instance.post('/auth/refresh', {});
            const res = await axios(error.response.config);
            return res;
          } catch (error) {
            userStore.reset();
            navigateTo('/login');
            return Promise.reject(error);
          }
        },
      );
    };

    createAxiosResponseInterceptor();

    return instance;
  },

  disk: () => {
    const config = useRuntimeConfig();
    const userStore = useUserStore();

    const instance = axios.create({
      baseURL: config.public.API_URL,
      headers: {
        'Content-Type': 'multipart/formdata',
      },
      withCredentials: true,
    });

    const createAxiosResponseInterceptor = () => {
      const interceptor = instance.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          if (error.response.status !== 401) throw error;

          instance.interceptors.response.eject(interceptor);

          try {
            await instance.post('/auth/refresh', {});
            const res = await axios(error.response.config);
            return res;
          } catch (error) {
            userStore.reset();
            navigateTo('/login');
            return Promise.reject(error);
          }
        },
      );
    };

    createAxiosResponseInterceptor();

    return instance;
  },
};

export default instances;
