import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';
import * as pkg from '~/package.json';

export default defineNuxtPlugin((nuxtApp) => {
  const release = pkg.version;
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const router = useRouter();

  console.log({ release }, sentry.environment);

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    release,
    environment: sentry.environment,

    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\//],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay({
        blockAllMedia: false,
        useCompression: false,
        maskAllInputs: false,
        maskAllText: false,
      }),
      Sentry.metrics.metricsAggregatorIntegration(),
    ],

    tracesSampleRate: 1, // Change in prod

    replaysSessionSampleRate: 0.1, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    profilesSampleRate: 1.0,

    debug: false,

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line
        console.error(`[sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    },
  });

  try {
    const user = useCookie('user')?.value?.user;
    if (user && Object.keys(user).length > 0) {
      const username = [user.phone, user.last_name, user.first_name, user.secondary_name]
        .filter((i) => i !== undefined && i !== '')
        .join(' ');

      const pageqs = useCookie('pageqs')?.value || '';
      Sentry.setUser({ username, click: pageqs });
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
});
