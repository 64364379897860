<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
useHead({
  htmlAttrs: {
    'data-theme': () => 'light',
    class: () => 'light',
  },
});
</script>
