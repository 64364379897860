import { defineStore } from 'pinia';
import { useNotieStore } from './notification';

export const useUserStore = defineStore(
  'user',
  () => {
    const { $api } = useNuxtApp();
    const notificationStore = useNotieStore();

    const isLoggedIn = ref(false);
    const id = ref('');
    const displayedName = ref('');
    const email = ref('');
    const role = ref('');

    const isLoading = ref(false);

    const login = async (_data) => {
      try {
        isLoading.value = true;
        const {
          data: { data },
        } = await $api.web().login(_data);

        isLoggedIn.value = true;
        id.value = data.user.id;
        displayedName.value = data.user.displayedName;
        email.value = data.user.email;
        role.value = data.user.role;
        isLoading.value = false;
        if (role.value !== 'hr') navigateTo('/blog/1');
        else navigateTo('/vacancies/1');
      } catch (error) {
        isLoading.value = false;
        notificationStore.add({ type: 'error', message: 'Неверный логин или пароль' });
      }
    };

    const logout = async () => {
      await $api.web().logout();
      isLoggedIn.value = false;
      id.value = '';
      displayedName.value = '';
      email.value = '';
      role.value = '';
      navigateTo('/login');
    };

    const reset = () => {
      isLoggedIn.value = false;
      id.value = '';
      displayedName.value = '';
      role.value = '';
      email.value = '';
    };

    // const storage = persistedState.localStorage;

    return {
      isLoggedIn,
      id,
      displayedName,
      email,
      role,
      isLoading,

      login,
      logout,
      reset,
    };
  },
  { persist: true },
);
