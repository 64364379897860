export default (instance) => {
  return {
    getAllFiles(page) {
      return instance().get(`/filesUpload/page/${page}`);
    },
    uploadFiles(files, id) {
      return instance().post(`/filesUpload?userId=${id}`, files);
    },
    uploadFilesForApplication(files) {
      return instance().post(`/filesUploadForClient?userId=123`, files);
    },
    deleteFile(id) {
      return instance().delete(`/filesUpload/${id}`);
    },
    updateFile(file) {
      return instance().put(`/filesUpload/${file.id}`, file, {
        headers: { 'Content-Type': 'application/json' },
      });
    },
    synchronizeFiles(renameFiles) {
      return instance().get(`/filesUpload/sync`, {
        headers: { 'Content-Type': 'application/json' },
        params: {
          rename: +renameFiles,
        },
      });
    },
  };
};
