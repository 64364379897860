export default (instance) => {
  return {
    // auth

    login(data) {
      return instance().post('auth/login', data);
    },
    logout() {
      return instance().post('auth/logout');
    },
    refresh() {
      return instance().post('/auth/refresh', {});
    },
    // NEWS
    getAllPublishedNews(data) {
      return instance().get(`/news/page/${data.page}`, {
        params: {
          filter: data.filter,
        },
      });
    },
    getAllNews(page, filter) {
      return instance().get(`/news/all/page/${page}`, {
        params: {
          filter,
        },
      });
    },
    addNews(payload) {
      return instance().post('/news', payload);
    },
    updateNews(name, payload) {
      return instance().put(`/news/${name}`, payload);
    },
    getOnePublishedNews({ name }) {
      return instance().get(`/news/${name}`);
    },
    getOneNews({ name }) {
      return instance().get(`/news/all/${name}`);
    },
    deleteNews(name) {
      return instance().delete(`/news/${name}`);
    },

    // BLOG
    getPublishedBlogPosts(data) {
      return instance().get(`/blog/page/${data.page}`, {
        params: {
          filter: data.filter,
          categoryId: data.categoryId,
        },
      });
    },
    getAllBlogPosts(data) {
      return instance().get(`/blog/all/page/${data.page}`, {
        params: {
          filter: data.filter,
          category_id: data.categoryId,
        },
      });
    },
    getBlogCategories() {
      return instance().get('/blog/categories');
    },
    addBlogCategory(payload) {
      return instance().post('/blog/categories', payload);
    },
    addBlogPost(payload) {
      return instance().post('/blog', payload);
    },
    updateBlogPost(name, payload) {
      return instance().put(`/blog/${name}`, payload);
    },
    updateCategories(payload) {
      return instance().put(`/blog/categories/update`, { modified_categories: [payload] });
    },
    updateCategoriesPositions(payload) {
      return instance().put('/blog/categories/positions', payload);
    },
    getOnePublishedBlogPost({ name, category }) {
      return instance().get(`/blog/${category}/${name}`);
    },
    getOneBlogPost({ name, category }) {
      return instance().get(`/blog/all/${category}/${name}`);
    },
    deleteBlogPost(name) {
      return instance().delete(`/blog/${name}`);
    },
    deleteCategory(id) {
      return instance().delete(`/blog/categories/${id}`);
    },

    // FAQ
    getAllFaqs() {
      return instance().get('/faq');
    },
    getOneFaq(id) {
      return instance().get(`/faq/${id}`);
    },
    addFaq(payload) {
      return instance().post(`/faq`, payload);
    },
    updateFaq(id, payload) {
      return instance().put(`/faq/${id}`, payload);
    },
    updateAllFaq(payload) {
      return instance().put(`/faq/positions/update`, payload);
    },
    deleteFaq(id) {
      return instance().delete(`/faq/${id}`);
    },

    // USERS
    getAllUsers() {
      return instance().get('/users');
    },
    getUser(id) {
      return instance().get(`/users/${id}`);
    },
    updateUser(id, user) {
      return instance().put(`/users/${id}`, user);
    },
    registerUser(payload) {
      return instance().post(`/auth/register`, payload);
    },
    deleteUser(id) {
      return instance().delete(`/users/${id}`);
    },

    // REVIEWS
    getAllReviews() {
      return instance().get('/reviews');
    },
    getOneReviews(id) {
      return instance().get(`/reviews/${id}`);
    },
    addReviews(payload) {
      return instance().post('/reviews', payload);
    },
    updateReviews(id, payload) {
      return instance().put(`/reviews/${id}`, payload);
    },
    updateAllReviews(payload) {
      return instance().put(`/reviews/positions/update`, payload);
    },
    deleteReviews(id) {
      return instance().delete(`/reviews/${id}`);
    },

    // VACANCIES
    getPublishedVacancies() {
      return instance().get('/vacancies');
    },
    getPaginatedVacancies(data) {
      return instance().get(`/vacancies/page/${data.page}`, {
        params: {
          filter: data.filter,
        },
      });
    },
    getOneVacancy(params) {
      return instance().get(`/vacancies/${params.city}/${params.name}`);
    },
    addVacancy(payload) {
      return instance().post('/vacancies', payload);
    },
    updateVacancy(id, payload) {
      return instance().put(`/vacancies/${id}`, payload);
    },
    deleteVacancy(id) {
      return instance().delete(`/vacancies/${id}`);
    },

    // FEEDBACK
    sendFeedback(token) {
      return instance().post('/feedback', token);
    },

    // APPLICATION
    addApplication(payload) {
      return instance().post('/application', payload);
    },

    // DOCUMENTS
    getAllDocuments() {
      return instance().get('/documents');
    },
    // documents
    addDocument(payload) {
      return instance().post('/documents', payload);
    },
    updateDocument(doc) {
      return instance().put(`/documents/${doc.id}`, doc);
    },
    deleteDocument(id) {
      return instance().delete(`/documents/${id}`);
    },
    // sections
    addSection(name) {
      return instance().post('/documents/sections', { name });
    },
    updateSection(section) {
      return instance().put(`/documents/sections/${section.id}`, section);
    },
    deleteSection(id) {
      return instance().delete(`/documents/sections/${id}`);
    },

    // PARTNER
    getPartners() {
      return instance().get('/partners');
    },
    addNewPartner(payload) {
      return instance().post('/partners', payload);
    },
    deletePartner(id) {
      return instance().delete(`/partners/${id}`);
    },
    updatePartners(payload) {
      return instance().put('/partners/positions', payload);
    },
    updatePartner(payload) {
      return instance().put('/partners', payload);
    },

    // SETTINGS
    getOnePublicSetting(setting) {
      return instance().get(`/settings/${setting}`);
    },
    getOneSetting(setting) {
      return instance().get(`/settings/all/${setting}`);
    },
    getAllSettings() {
      return instance().get('/settings');
    },
    updateSettings(settings) {
      return instance().put('/settings', settings);
    },
    updateOneSetting(data) {
      return instance().put(`/settings/${data.name}`, { value: data.value });
    },
    updateAllCities(payload) {
      return instance().put(`/cities/positions`, payload);
    },
    // cities
    getCities() {
      return instance().get(`/cities`);
    },
    getCity(name) {
      return instance().get(`/cities/${name}`);
    },
    addCity(newCity) {
      return instance().post(`/cities`, newCity);
    },
    updateCities(modifiedCities) {
      return instance().put('/cities', { modified_cities: [modifiedCities] });
    },
    deleteCity(id) {
      return instance().delete(`/cities/${id}`);
    },

    // PAGES
    getAllPageData(name) {
      return instance().get(`/pages/all/page/${name}`);
    },
    getPageData(name) {
      return instance().get(`/pages/page/${name}`);
    },
    getAllPages() {
      return instance().get('/pages');
    },
    getSitemapPages() {
      return instance().get('/pages/sitemap');
    },
    getDynamicPages(data) {
      return instance().get(`/pages/dynamic/${data.page}`, {
        params: {
          filter: data.filter,
        },
      });
    },
    getStaticPages() {
      return instance().get('/pages/static');
    },
    addPage(data) {
      return instance().post('/pages', data);
    },
    updatePage(data) {
      return instance().put('/pages', data);
    },
    deletePage(id) {
      return instance().delete(`/pages/${id}`);
    },

    // APPLICATION
    getLoanApplication(payload) {
      return instance().post('/getLoanApplication', payload);
    },
    addAppealApplication(payload) {
      return instance().post('/appeal', payload);
    },
    getAllApplications(page) {
      return instance().get(`/applications/page/${page}`);
    },
    getAllAppealApplications(page) {
      return instance().get(`/applications/appeals/page/${page}`);
    },
    deleteApplication(id) {
      return instance().delete(`/applications/${id}`);
    },

    // FEEDBACK
    addFeedback(payload) {
      return instance().post(`/addFeedback`, payload);
    },
    getAllFeedback(page) {
      return instance().get(`/applications/feedback/page/${page}`);
    },

    // PAYMENT
    getPaymentServices() {
      return instance().get('/payment/service');
    },
    getPaymentUsers() {
      return instance().get('/payment/users');
    },
    addPaymentUser(payload) {
      return instance().post('/payment', payload);
    },
    getOnePaymentUser(id) {
      return instance().get(`/payment/user/${id}`);
    },
    updatePaymentUser(id, payload) {
      return instance().put(`/payment/user/${id}`, payload);
    },
    getPaymentContract(payload) {
      return instance().get('/payment/contract', {
        params: {
          payment_type: payload.payment_type,
          account_number: payload.account_number,
        },
      });
    },
    getTranInfo(payload) {
      return instance().get('/payment/traninfo', {
        params: {
          tran_id: payload,
        },
      });
    },
    getSendMessage(payload) {
      return instance().get('/payment/sendMessage', {
        params: {
          account_number: payload.account_number,
        },
      });
    },
    validateCode(payload) {
      return instance().get('/payment/validateMessageCode', {
        params: {
          id: payload.id,
          code: payload.code,
        },
      });
    },
    payTheContract(payload) {
      return instance().post('/payment/contract/pay', payload);
    },

    // PAYMENT METHODS
    getPaymentMethods() {
      return instance().get('/paymentMethods');
    },
    getOnePaymentMethod(id) {
      return instance().get(`/paymentMethods/${id}`);
    },
    updatePaymentMethod(id, payload) {
      return instance().put(`/paymentMethods/${id}`, payload);
    },
    addPaymentMethod(payload) {
      return instance().post('/paymentMethods', payload);
    },
    updateAllPaymentMethods(payload) {
      return instance().put('/paymentMethods/positions/update', payload);
    },
    deletePaymentMethod(id) {
      return instance().delete(`/paymentMethods/${id}`);
    },

    // VALIDATION
    checkUserRole() {
      return instance().get(`/validation/role`);
    },

    getGeoPaths() {
      return instance().get(`/geoPath`);
    },
    getOneGeoPaths(path) {
      return instance().get(`/geoPath/${path}`);
    },
  };
};
