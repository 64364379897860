<template>
  <NuxtLayout>
    <div></div>
  </NuxtLayout>
</template>

<script setup>
import { useUserStore } from './store/user';

const userStore = useUserStore();

if (userStore.isLoggedIn === false) navigateTo('/login');
if (userStore.isLoggedIn && userStore.role !== 'hr') navigateTo('/news/1');
else navigateTo('/vacancies/1');
</script>
