import { useNotieStore } from '~/store/notification';

export default defineNuxtPlugin((nuxtApp) => {
  const notieStore = useNotieStore();
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    // if (error.response.status === 401) return;

    notieStore.addNotification({
      type: 'error',
      message: 'Что-то пошло не так',
    });
    console.log({ error, instance, info });
    // handle error, e.g. report to a service
  };

  //   // Also possible
  //   nuxtApp.hook('vue:error', (error, instance, info) => {
  //     // handle error, e.g. report to a service
  //   });
});
