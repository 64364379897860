import instances from './instanes';
import web from './web';
import disk from './disk';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      api: {
        web: () => web(instances.web),
        disk: () => disk(instances.disk),
      },
    },
  };
});
