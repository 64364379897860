export default defineNuxtPlugin(() => {
  return {
    provide: {
      prepareDescription: (str) => {
        const replacedSpaces = str.replace(/\s+/g, ' ');

        if (replacedSpaces.length <= 160) {
          return str;
        }

        const parsed = replacedSpaces.substring(0, 160).split(' ');

        while (parsed.join(' ').length > 156) {
          parsed.splice(-1, 1);
        }

        parsed.push('...');

        return parsed.join(' ');
      },
    },
  };
});
